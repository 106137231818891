/* eslint-disable import/no-named-as-default */
import notify from 'notify';
import React, { useState, useReducer, useEffect } from 'react';
import { useQueryParam } from 'use-query-params';
import { tryGetFirstError } from 'utils/requests';
import {
  getSignaturesExternal,
  applySignature,
  getMergedPdfDocumentByView
} from './api';
import { SignInfo, SignInfoScreenValues, Node } from './types';
import documentReducer, { DocumentReducerState, DocumentReducerAction } from './documentReducer';
import { navigate } from 'gatsby';
interface SignContextValues {
  signId?: string;
  documentId?: number;
  signatureInfo?: SignInfo;
  signedNodes: number[];
  document: DocumentReducerState;
  signatures: Node[];
  error: boolean;
  completedSignInfoScreen: boolean;
  setCompletedSignInfoScreen: React.Dispatch<React.SetStateAction<boolean>>;
  loadingNextDocument: boolean;
  setLoadingNextDocument: React.Dispatch<React.SetStateAction<boolean>>;
  signInfoScreenValues: SignInfoScreenValues;
  setSignInfoScreenValues: React.Dispatch<React.SetStateAction<SignInfoScreenValues | undefined>>;
  documentDispatch: React.Dispatch<DocumentReducerAction>;
  setSignatureInfo: React.Dispatch<React.SetStateAction<SignInfo | undefined>>;
  setDocumentId: React.Dispatch<React.SetStateAction<number | undefined>>;
  submitSignature: (value: number) => void;
  signDocument: () => void;
}

const SignContext = React.createContext<SignContextValues>({} as SignContextValues);

export const SignContextWrapper = ({ children }) => {
  var signaturesLeft: Node[];
  const [signId] = useQueryParam<string>('view');
  const [documentId, setDocumentId] = useQueryParam<number | undefined>('id');
  const [signedNodes, setSignedNodes] = useState<number[]>([]);
  const [signatureInfo, setSignatureInfo] = useState<SignInfo>();
  const [signInfoScreenValues, setSignInfoScreenValues] = useState<SignInfoScreenValues>();
  const [error, setError] = useState<boolean>(false);
  const [completedSignInfoScreen, setCompletedSignInfoScreen] = useState<boolean>(false);
  const [loadingNextDocument, setLoadingNextDocument] = useState<boolean>(false);
  const [document, documentDispatch] = useReducer(documentReducer, {
    nodes: [],
    loaded: false,
    currentPage: 1,
  });

  const signatures: Node[] = document.nodes
    .filter(
      node =>
        node.role === signatureInfo?.role && (node.type === 'signature' || node.type === 'initials')
    ).sort(
      function(a, b) {          
         if (a.page === b.page) {
            return a.position.y - b.position.y;
         }
         return a.page > b.page ? 1 : -1;
      });
  // const [currentSelection, setCurrentSelection] = useState<number>();
  
  const getNextUnsignedNode = (arr: Node[]) => {
    if (!arr.length || arr.length==0) return;
    const match: Node = arr.shift()!;

    if (signedNodes.includes(match.id)) {
      return getNextUnsignedNode(arr);
    }
    return match;
  };

  const { currentPage } = document;

  const submitSignature = value => {
 
    const nodeToUpdate = document.nodes.find(node => node.id === value);
    const nodeText =
      nodeToUpdate?.type === 'signature' ? signatureInfo?.signature : signatureInfo?.initials;
    documentDispatch({ type: 'SET_SIGNATURES', value });
    documentDispatch({ type: 'SIGN_NODE', value: { ...nodeToUpdate!, text: nodeText || '' } });
    setSignedNodes(v => [...v, value]);
    
     
    if(!signaturesLeft){
      signaturesLeft = signatures;
    }
    

    const delimiter = signaturesLeft.findIndex(signature => signature.id === value) ;
    
  
    signaturesLeft.splice(delimiter,1);
    var searchInArray = signaturesLeft;

 

    const targetNode: Node = getNextUnsignedNode(!searchInArray.length ? signatures : searchInArray);

    
    // setCurrentSelection(targetNode.id);
    
    if (targetNode && currentPage !== targetNode.page) {
      documentDispatch({ type: 'SET_CURRENT_PAGE', value: targetNode.page });
    }
    if (targetNode && window.innerWidth > 767) {
      documentDispatch({ type: 'SET_NODE_TO_SCROLL', value: targetNode.id });
    }
    signaturesLeft = searchInArray;
  
  };

  const signDocument = async () => {
    setLoadingNextDocument(true);
    documentDispatch({ type: 'SET_DOCUMENT_LOADED', value: false });
    documentDispatch({ type: 'SET_CURRENT_PAGE', value: 1 });
    signatureInfo.nextDocument ? notify("Please wait... Next document is being loaded.") : "";

    try {
      await applySignature(signId, {
        signature: signatureInfo?.signature,
        initials: signatureInfo?.initials
      });
    } catch (err) {
      notify(tryGetFirstError(err));
      setError(true);
    }

    if(signatureInfo?.nextDocument){
      navigate(`/pdf-editor/sign?view=${signatureInfo.nextDocument}`);
      documentDispatch({ type: 'SET_DOCUMENT_LOADED', value: false });
      setSignedNodes([]);
    }else{
      setSignatureInfo(v => ({ ...v, signed: true }));
    }
  };

  const getPdfFile = async () => {
    try {
      const url = await getMergedPdfDocumentByView(signId);
      
      documentDispatch({ type: 'SET_DOCUMENT_URL', value: url });
    } catch (err) {
      notify(tryGetFirstError(err));
      setError(true);
    }
  };

  const getSignatures = async () => {
    try {
      const { nodes, ...signatureInfo } = await getSignaturesExternal(signId);
      setSignatureInfo({...signatureInfo, ...signInfoScreenValues });
      documentDispatch({ type: 'SET_NODES', value: nodes });
      setLoadingNextDocument(false);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  useEffect(() => {
    if (!signatures || signatures.length == 0) return;
    if (signedNodes.length > 0) return;
    if(currentPage != signatures[0]['page']){
      documentDispatch({ type: 'SET_CURRENT_PAGE', value: signatures[0]['page'] });
    } 
  });

  useEffect(() => {
    if (document.loaded) return;
    documentDispatch({ type: 'SET_DOCUMENT_LOADED', value: true });
    getPdfFile();
    getSignatures();
  }, [signId]);

  const contextValues: SignContextValues = {
    signId,
    documentId,
    signatureInfo,
    signedNodes,
    signatures,
    setSignatureInfo,
    setDocumentId,
    submitSignature,
    signDocument,
    document,
    documentDispatch,
    error,
    completedSignInfoScreen,
    setCompletedSignInfoScreen,
    signInfoScreenValues,
    setSignInfoScreenValues,
    loadingNextDocument,
    setLoadingNextDocument
  };

  return (
    <SignContext.Provider value={{ ...contextValues }}>{children}</SignContext.Provider>
  );
};

export default SignContext;
